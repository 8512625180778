import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { OrderManagementComponent } from './component/order-management/order-management.component';
import { ClientManagementComponent } from './component/client-management/client-management.component';
import { OrderShowComponent } from './component/order-show/order-show.component';
import { ClientShowComponent } from './component/client-show/client-show.component';

import { NotificationManageComponent } from './component/notification-manage/notification-manage.component';
import { NotificationCreateComponent } from './component/notification-create/notification-create.component';
import { NotificationEditComponent } from './component/notification-edit/notification-edit.component';

import { AuthGuard } from './guard/auth.guard';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';

import { EmailOrderDetailComponent } from './component/email-order-detail/email-order-detail.component';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  // {path: 'login', component: LoginComponent},
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent }

    ]
  },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'notification-list', component: NotificationListComponent, canActivate: [AuthGuard] },
      { path: 'orders', component: OrderManagementComponent, },
      { path: 'order/:id', component: OrderShowComponent, canActivate: [AuthGuard] },
      { path: 'clients', component: ClientManagementComponent, canActivate: [AuthGuard] },
      { path: 'client-show/:id', component: ClientShowComponent, canActivate: [AuthGuard] },

      { path: 'create-notification', component: NotificationCreateComponent, canActivate: [AuthGuard] },
      { path: 'manage-notification', component: NotificationManageComponent, canActivate: [AuthGuard] },
      { path: 'edit-notification/:id', component: NotificationEditComponent, canActivate: [AuthGuard] },

      {
        path: 'email',
        children: [
          { path: 'order-detail/:id', component: EmailOrderDetailComponent, canActivate: [AuthGuard] }
        ]
      },
    ],
    data: {panel: "Default Panel"}
  },
  {
    // lazy-loading SectigoWebModule, sub-path defined in /module/sectigoweb/sectigoweb-routing.modules.ts
    path: 'sectigoweb',
    loadChildren: () => import('./module/sectigoweb/sectigoweb.module').then(m => m.SectigoWebModule),
    data: {panel: "Sectigoweb Panel"}
  },
  {
    // lazy-loading PromotionModule, sub-path defined in /module/promotion/promotion-routing.modules.ts
    path: 'promotion',
    loadChildren: () => import('./module/promotion/promotion.module').then(m => m.PromotionModule),
    data: {panel: "Promotion Panel"}
  },
  {
    path: 'marketing',
    loadChildren: () => import('./module/marketing/marketing.module').then(m => m.MarketingModule),
    data: {panel: "Marketing Panel"}
  },
  {
    path: 'email-notification',
    loadChildren: () => import('./module/email-notification/email-notification.module').then(m => m.EmailNotificationModule),
    data: {panel: "Email Notification Panel"}
  },
  {
    path: 'dns',
    loadChildren: () => import('./module/dns/dns.module').then(m => m.DnsModule),
    data: {panel: "DNS Panel"}
  },
  {
    path: 'whmcs',
    loadChildren: () => import('./module/whmcs/whmcs.module').then(m => m.WhmcsModule),
    data: {panel: "WHMCS Panel"}
  },
  {
    path: 'abuse',
    loadChildren: () => import('./module/abuse-automation/abuse-automation.module').then(m => m.AbuseAutomationModule),
    data: {panel: "Abuse Automation Panel"}
  },
  {
    path: 'reseller',
    loadChildren: () => import('./module/reseller/reseller.module').then(m => m.ResellerModule),
    data: {panel: "Reseller Panel"}
  },
  {
    path: 'product',
    loadChildren: () => import('./module/product/product.module').then(m => m.ProductModule),
    data: {panel: "Product Panel"}
  },
  {
    path: 'second-hand-domain',
    loadChildren: () => import('./module/second-hand-domain/second-hand-domain.module').then(m => m.SecondHandDomainModule),
    data: {panel: "Secondhand Domain Panel"}
  },
  {
    path: 'ssl',
    loadChildren: () => import('./module/ssl/ssl.module').then(m => m.SslModule),
    data: {panel: "SSL Panel"}
  },
  {
    path: 'namewrap',
    loadChildren: () => import('./module/namewrap/namewrap.module').then(m => m.NamewrapModule),
    data: {panel: "Namewrap Panel"}
  },
  {
    path: 'mimining',
    loadChildren: () => import('./module/mimining/mimining.module').then(m => m.MiminingModule),
    data: {panel: "Domain Backorder"}
  },
  {
    path: 'china-acceleration',
    loadChildren: () => import('./module/china-acceleration/china-acceleration.module').then(m => m.ChinaAccelerationModule),
    data: {panel: "China Acceleration Panel"}
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
