import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl, NgControl, Validators } from "@angular/forms";
import { Directive, Input, forwardRef, Output, EventEmitter, ElementRef, NgZone, Injector, OnInit, AfterViewInit, Renderer2 } from "@angular/core";
import { Observable, Subscriber } from 'rxjs';

declare const window: any;

@Directive({
  selector: '[hCaptcha]',
  exportAs: 'hCaptcha',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HCaptchaDirective),
    multi: true
  }]
})

export class HCaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor{
  @Input() siteKey: string;
  @Input() lang:string;
  
  @Output() captchaResponse = new EventEmitter<string>();
  @Output() captchaExpired = new EventEmitter();
  @Output() captchaError = new EventEmitter();

  @Output() captchaLoaded = new EventEmitter<any>();

  private control: UntypedFormControl;
  private widgetId: number;

  private onChange: (value: string) => void;
  private onTouched: (value: string) => void;

  constructor(private element: ElementRef, private ngZone: NgZone, private injector: Injector, private renderer: Renderer2) { }

  ngOnInit(){
    this.loadHCaptcha().subscribe(() => {
      const config = {
        'sitekey': this.siteKey,
        'callback': this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this),
        'error-callback': this.onError.bind(this)
      };

      this.widgetId = this.render(this.element.nativeElement, config);
      this.captchaLoaded.emit(this.widgetId);
    },
    (error)=>{
      console.error('Failed to load hCaptcha script', error);
      this.captchaError.emit();
    });
  }

  ngAfterViewInit(){

  }

  writeValue(value: any): void {

    // for form reset handling
    if (!value && window.hcaptcha) {
        window.hcaptcha.reset(this.widgetId);
    }
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  onError() {
    this.ngZone.run(() => {
        this.captchaError.emit();
        this.onChange(null);
        this.onTouched(null);
    });
  }

  onExpired() {
      this.ngZone.run(() => {
          this.captchaExpired.emit();
          this.onChange(null);
          this.onTouched(null);
      });
  }

  onSuccess(token: string) {
      this.ngZone.run(() => {
          //this.verifyToken(token);
          this.captchaResponse.emit(token);
          this.onChange(token);
          this.onTouched(token);
      });
  }

  private setValidators() {
    this.control.setValidators(Validators.required);
    setTimeout(() => this.control.updateValueAndValidity(), 0);
  }

  verifyToken(token: string) {
    //this.control.setAsyncValidators(this.reCaptchaAsyncValidator.validateToken(token))
    this.control.updateValueAndValidity();
  }

  /**
     * Renders the container as a hCAPTCHA widget and returns the ID of the newly created widget.
     * @param element
     * @param config
     * @returns {number}
     */
    private render(element: HTMLElement, config): number {
      return window.hcaptcha.render(element, config);
  }

  /**
    * Useful for multiple captcha
    * @returns {number}
    */
   getId() {
    return this.widgetId;
  }

  /**
     * Resets the hCAPTCHA widget.
     */
    reset(): void {
      if (!this.widgetId) return;
      window.hcaptcha.reset(this.widgetId);
      this.onChange(null);
  }

   /**
     * Gets the response for the reCAPTCHA widget.
     * @returns {string}
     */
    getResponse(): string {
      if (!this.widgetId)
          return window.hcaptcha.getResponse(this.widgetId);
  }

  loadHCaptcha(): Observable<void> {
    return new Observable<void>((observer: Subscriber<void>) => {
        // The hCaptcha script has already been loaded
        if (typeof window.hcaptcha !== 'undefined') {
            observer.next();
            observer.complete();
            return;
        }

        const script = document.createElement('script');
        const lang = this.lang ? '&hl=' + this.lang : '';
        script.src = `https://hcaptcha.com/1/api.js?render=explicit${lang}`;
        script.async = true;
        script.defer = true;
        script.onerror = (e) => observer.error(e);
        script.onload = () => {
            observer.next();
            observer.complete();
        };
        document.head.appendChild(script);
    });
}
}
