import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {StoreService} from './store.service'
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StoreFrontService {

  apiUrl = environment.apiUrl;
  mainApiUrl = environment.mainApiUrl;

  httpHeaders = {
    headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  constructor(
    private http: HttpClient,
    public storeService:StoreService
    ) { }

  getAllStoreFront(){
    console.log(this.httpHeaders)
    return this.http.get(this.apiUrl+'storefront/getAllStoreFront', this.httpHeaders)
  }
  getCountries() {
    return this.http.get(`${this.mainApiUrl}countries`);
  }
  getStates(countryId) {
    return this.http.get(
      `${this.mainApiUrl}country/${countryId}/states`
      );
  }
  getCities(cityId) {
    return this.http.get(
      `${this.mainApiUrl}state/${cityId}/cities`
      );
  }

  getAllCovers(){

    return this.http.get(this.apiUrl+'storefront/get-covers', this.httpHeaders)
  }
  getAllThemes(){

    return this.http.get(this.apiUrl+'storefront/get-themes', this.httpHeaders)
  }
  getAllCategories(){

    return this.http.get(this.apiUrl+'storefront/get-categories', this.httpHeaders)
  }

  getAllProducts(){
    return this.http.get(this.apiUrl+'storefront/get-products', this.httpHeaders)

  }

  getAllPaymentMethods(){
    return this.http.get(this.apiUrl+'storefront/get-payments', this.httpHeaders)

  }

  createStorefront(){
    let data = {
      storefront_name:this.storeService.storefrontName,
      storefront_code:this.storeService.storefrontCode,
      department:this.storeService.department,
      address:this.storeService.address,
      email:this.storeService.email,
      phone_number:this.storeService.phoneNumber,
      state_id:this.storeService.stateId,
      country_id:this.storeService.countryId,
      selected_cover:this.storeService.selectedCover,
      cover_title:this.storeService.coverTitle,
      cover_text:this.storeService.coverText,
      theme_id:this.storeService.themeId,
      custom_page_status:true,
      page_title:this.storeService.pageTitle,
      page_content:this.storeService.pageContent,
      selected_products:this.storeService.selectedProducts,
      selected_products_price:this.storeService.monthlyPrice,
      selected_yearly_products_price:this.storeService.yearlyPrice,
      selected_payment:[1],
      paypal_recurring:this.storeService.acceptRecurring,
      paypal_merchant:this.storeService.companyMerchant,
      paypal_merchant_client_id:this.storeService.clientId,
      paypal_merchant_secret_key:this.storeService.secretKey,
      paypal_location_based_currency:this.storeService.locationCurrency,
      storefront_logo:this.storeService.logo,
      custom_cover:this.storeService.customCover
    }
    console.log(data)
    return this.http.post(this.apiUrl+'storefront/create-store', data,this.httpHeaders)


  }
}
