import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { OrderManagementComponent } from './component/order-management/order-management.component';
import { ClientManagementComponent } from './component/client-management/client-management.component';
import { MasterTemplateComponent } from './component/master-template/master-template.component';
import { ClientShowComponent } from './component/client-show/client-show.component';
import { OrderShowComponent } from './component/order-show/order-show.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { PreviewPageComponent } from './preview-page/preview-page.component';
import { NotificationManageComponent } from './component/notification-manage/notification-manage.component';
import { NotificationCreateComponent } from './component/notification-create/notification-create.component';
import { NotificationEditComponent } from './component/notification-edit/notification-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PushNotificationService } from './shared/push-notification.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat/';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AsideMenuComponent } from './component/aside-menu/aside-menu.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { EmailModule } from './module/email/email.module';
import { SharedModule } from './module/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HCaptchaDirective } from './directive/h-captcha.directive';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    //HeaderComponent,     ----> moved to shared module
    LoginComponent,
    DashboardComponent,
    OrderManagementComponent,
    ClientManagementComponent,
    MasterTemplateComponent,
    ClientShowComponent,
    OrderShowComponent,
    PreviewPageComponent,
    NotificationManageComponent,
    NotificationCreateComponent,
    NotificationEditComponent,
    AsideMenuComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    NotificationListComponent,
    HCaptchaDirective,
  ],
  imports: [
    //BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CKEditorModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot({
      maxOpened: 5,
      autoDismiss: true
    }),

    EmailModule,

    SharedModule,
    // import ngx-bootstrap library modules, do remember to import in 
    // sub-module too without the .forRoot()
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
  ],
  providers: [
    PushNotificationService,
    AsyncPipe,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
