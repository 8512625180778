import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/service/client.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

//  declare var $: any;
//   declare var jQuery: any;
  declare var appJS: any;

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.css']
})
export class ClientManagementComponent implements OnInit {

  clients:any
  dataTable: any;

  constructor(
    private router:Router,
    private clientService:ClientService,
    private chRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getAllCustomer()
  }

  getAllCustomer(){
    this.clientService.getAllClients().subscribe(
      result => {
        this.clients = result['data']
        console.log(this.clients)
        this.chRef.detectChanges();
        const table: any = $('table');
        this.dataTable = table.DataTable()
      }, error => {

      }
    )
  }

  show(client){
    this.router.navigateByUrl('/client-show/'+client.id)
  }

}
