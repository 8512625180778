<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard"><i class="icon-speedometer"></i> Dashboard</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/orders"><i class="icon-basket-loaded"></i> Order
          Management</a>
      </li>


      <li class="nav-item">
        <a class="nav-link" routerLink="/clients"><i class="icon-people"></i> Client Management</a>
      </li>

      <li class="nav-item nav-dropdown" id="notification-nav-dropdown">
        <a class="nav-link nav-dropdown-toggle" id="notification-nav" style="cursor: pointer;"><i class="icon-cursor-move"></i> Notification Settings</a>
        <ul class="nav-dropdown-items" id="dropdown-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/create-notification"><i class="icon-bell"></i> Create Notification</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/manage-notification"><i class="icon-bell"></i> Manage Notification</a>
          </li>
        </ul>
      </li>


    </ul>
  </nav>
  <button class="sidebar-minimizer brand-minimizer" type="button"></button>
</div>
