import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NotificationService} from '../../service/notification.service'
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

declare var appJS: any;

@Component({
  selector: 'app-notification-manage',
  templateUrl: './notification-manage.component.html',
  styleUrls: ['./notification-manage.component.css']
})
export class NotificationManageComponent implements OnInit {

  notifications = []
  dataTable:any
  notificationSuccess: boolean = false;

  constructor(private router:Router,
    private notificationService:NotificationService,
    private chRef: ChangeDetectorRef,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.notificationService.getAllNotification().subscribe(data=>{
      console.log(data)
      this.notifications = data["data"]
      this.chRef.detectChanges();

      $(document).ready(function() {
        this.orderByResellerDone = true;
        
        // Setup - add a text input to each footer cell
        $('table tfoot th').each( function (index) {
          if ( index == 2) {
            $(this).html( `<select class="form-control">
                            <option value="">No Filter</option>
                            <option value="Info">Info</option>
                            <option value="Deeplink">Deeplink</option>
                          </select>` );
          } else {
            var title = $(this).text();
            $(this).html( '<input type="text" class="form-control" placeholder="Search '+title+'" />' );
          }
        });

        // DataTable
        // var table : any = $('#orders')
        // this.dataTable =  table.DataTable({
        //   "order": [[ 5, "desc" ]]
        // });
        const table: any = $('table');
        this.dataTable = table.DataTable({
          "order": [[ 3, "desc" ]] // Set publish time data to descending order 
        });

        // Apply the search
        this.dataTable.columns().every( function (index) {
          var that = this;
          console.log(index)

          if (index == 2) {
            $( 'select', this.footer() ).on('change', function () {
                if ( that.search() !== $(this).val() ) {
                    that
                        .search( $(this).val() )
                        .draw();
                }
            });
          } else {
            $( 'input', this.footer() ).on( 'keyup change', function () {
                if ( that.search() !== $(this).val() ) {
                    that
                        .search( $(this).val() )
                        .draw();
                }
            });
          }
      } );
      });
    }, error => {
      console.log(error)
    });
  }

  edit(id) {
    this.router.navigateByUrl('/edit-notification/'+id)
  }

}
