import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';


@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	apiUrl = environment.apiUrl;
  	mainApiUrl = environment.mainApiUrl;

	httpHeaders = {
		headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
	}
	constructor(private http: HttpClient) { }

	getAllNotification(){
		console.log(this.httpHeaders)
		return this.http.get(this.apiUrl+'notification/get-all-res-notifications', this.httpHeaders)
	}

	getNotificationById(id){
		return this.http.get(this.apiUrl+'notification/get-res-notification-by-id/'+id, this.httpHeaders)
	
	}
	createNotification(data){
		return this.http.post(this.apiUrl+'notification/create-res-notification',data, this.httpHeaders)
	
	}

	editNotification(data){
		return this.http.post(this.apiUrl+'notification/update-res-notification',data, this.httpHeaders)	
	}
}
