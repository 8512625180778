import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiUrl;
  mainApiUrl = environment.mainApiUrl;

  tapDataResponse = (response:any) =>{

  }

  constructor(
    private http: HttpClient
  ) { }

  postLogin(data){
    //console.log('credentials :',data)
    return this.http.post(this.apiUrl+'rerlogin', data)
    //.pipe(tap(this.tapDataResponse));
  }

  checkAuth(access_token) 
  {
    return this.http.post(this.apiUrl+'verify-token-db', {"accessToken" : access_token})
  }
}
