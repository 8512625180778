import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/service/order.service';
import { ActivatedRoute } from '@angular/router';
import { ScrollUtil } from 'src/app/util/scroll-util';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;

@Component({
  selector: 'app-order-show',
  templateUrl: './order-show.component.html',
  styleUrls: ['./order-show.component.css']
})
export class OrderShowComponent implements OnInit {

  order:any
  storefront:any
  subscription:any
  orderStatus = 'Incomplete';
  successMessage = null;
  errorMessage = null;
  refundReason = '';
  refundButtonPressed = false;
  proceedButtonPressed = false;
  paymentDoneButtonPressed = false;

  constructor(
    private orderService:OrderService,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id')
    this.getOrderById(id)
  }

  getOrderById(id){
    this.orderService.getOrderById(id).subscribe(
      result => {
        this.order = result['order']
        this.storefront = result['storefront']
        if (result['subscriptions'] && result['subscriptions'].length > 0) {
          this.subscription = result['subscriptions'][0];
        }
        
        if(this.order['status'] == 'Success' && this.order['payment_details'][0]['status'] == 'Success') {
          this.orderStatus = 'Completed';
        }

        console.log(result)
      }, error => {

      }
    )
  }

  paymentDone(id){
    this.paymentDoneButtonPressed = true;

    this.orderService.postPaymentStatus(id, this.order.sf_id, this.order.sf_user_id).subscribe(
      result => {
        console.log(result)
        this.successMessage = "Payment marked as done.";
        this.errorMessage = null;
        this.paymentDoneButtonPressed = false;
        this.getOrderById(this.order.order_id)
        ScrollUtil.scrollToTop();
      }, error => {
        this.successMessage = null;
        this.errorMessage = "Failed to mark payment as done.";
        this.paymentDoneButtonPressed = false;
        this.getOrderById(this.order.order_id)
        ScrollUtil.scrollToTop();
      }
    )
  }

  proceedOrder(id, sf_id, sf_user_id){
    this.proceedButtonPressed = true;

    this.orderService.postProceedOrder(id, sf_id, sf_user_id).subscribe(
      result => {
        console.log(result)
        this.successMessage = "Order proceed successfully.";
        this.errorMessage = null;

        this.proceedButtonPressed = false;
        $('#order-dialog').modal('hide');
        ScrollUtil.scrollToTop();
        this.getOrderById(this.order.order_id)
      }, error => {
        console.log(error)
        this.errorMessage = "Failed to proceed order.";
        this.successMessage = null;
        this.getOrderById(this.order.order_id)
        this.proceedButtonPressed = false;
        $('#order-dialog').modal('hide');
        ScrollUtil.scrollToTop();
      }
    )
  }

  refundOrder(){
    this.refundButtonPressed = true;

    this.successMessage = null;
    this.errorMessage = null;

    let orderId = this.order['order_id'];
    let itemId = this.order['order_items'][0]['item_id'];
    let reason = this.refundReason.trim();

    this.orderService.postRefundOrder(orderId, itemId, reason, this.order.sf_id, this.order.sf_user_id).subscribe(
      result => {
        this.successMessage = "Successfully submitted refund request.";
        this.refundButtonPressed = false;
        $('#refund-dialog').modal('hide');
        this.getOrderById(this.order.order_id)
        ScrollUtil.scrollToTop();
      }, error => {
        this.errorMessage = "Failed to submit refund request.";
        this.refundButtonPressed = false;
        $('#refund-dialog').modal('hide');
        ScrollUtil.scrollToTop();
        this.getOrderById(this.order.order_id)
      }
    )
  }

  closeAlertMsgSuccess() {
    this.successMessage = null;
  }
  
  closeAlertMsgError() {
    this.errorMessage = null;
  }
  
  scrollToTop() {
		(function smoothscroll() {
			var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - (currentScroll / 8));
			}
		})();
	}

}
