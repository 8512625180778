import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor() { 

  }

  ngOnInit() {
    var $body = document.getElementsByTagName("BODY")[0];

    $body.classList.remove("sidebar-minimized");
    $body.classList.remove("brand-minimized");

    $("#notification-nav").click(function() {
    	$('#notification-nav-dropdown').toggleClass('open');
    });

  }

}
