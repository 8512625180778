import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	apiUrl = environment.apiUrl;
  	mainApiUrl = environment.mainApiUrl;

	httpHeaders = {
		headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
	}


	constructor(private http: HttpClient) { }

	getDashboardInfo(){
		return this.http.get(this.apiUrl+'dashboard/get-dashboard-data', this.httpHeaders)
	}

	getSalesChartData(days){
		return this.http.get(`${this.apiUrl}dashboard/get-sales-chart-data?days=${days}`, this.httpHeaders)
	}

	
}
