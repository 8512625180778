import { Component, OnInit } from '@angular/core';
declare var appJS: any;

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    appJS.init();
  }

}
