import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../component/header/header.component';
import { SortableTableHeader } from '../directive/sortable.directive';
import { SearchableTableHeader } from '../directive/searchable.directive';
import { MinNumberValidatorDirective } from '../directive/validator/min-num-validator.directive';
import { MaxNumberValidatorDirective } from '../directive/validator/max-num-validator.directive';
import { NumberDecimaLimitDirective } from '../directive/num-decimal-limit.directive';
import { DigitOnlyDirective } from '../directive/digit-only.directive';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
     ],
    declarations: [
        HeaderComponent,

        // declare custom directives
        SortableTableHeader,
        SearchableTableHeader,

        MinNumberValidatorDirective,
        MaxNumberValidatorDirective,
        NumberDecimaLimitDirective,
        DigitOnlyDirective,
    ],
    exports: [
        HeaderComponent,

        // export custom directives
        SortableTableHeader,
        SearchableTableHeader,

        MinNumberValidatorDirective,
        MaxNumberValidatorDirective,
        NumberDecimaLimitDirective,
        DigitOnlyDirective
    ]
})
export class SharedModule {
    // this module used to import and export the modules or components for reuse and sharing purpose among multiple modules
    // eg.  Module A and Module B need the WhatSoEverComponent, both Module A and B just require to
    //      import this SharedModule then they can access the WhatSoEverComponent
}