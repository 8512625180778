import { EmailOrderDetail } from '../model/email/email-order-detail.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private emailServiceApiUrl:any = environment.emailService.apiUrl;
  private appName:string = environment.emailService.appName;

  constructor(private httpClient:HttpClient) {
  }

  getHeaders(){
    // create new instance for each api call
    const httpHeaders = {
      headers : new HttpHeaders()
      .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
      .set('Content-Type', 'application/json')
    }
    return httpHeaders;
  }

  getEmailOrders(){
    let endpoint :string = '/order/search';
    let bodyData:any = {
      orderStatus: 'ALL'
    };
    return this.httpClient.post(this.emailServiceApiUrl + endpoint, bodyData, this.getHeaders() );
  }

  getEmailOrderDetails(oid:number){
    let endpoint:string = '/order/info/'+oid;
    return this.httpClient.get(this.emailServiceApiUrl + endpoint, this.getHeaders() );
  }

  updateEmailOrderStatus(oid:number, status:string){
    let endpoint: string = '/order/update/status';
    let bodyData = {oid:oid, status:status,appName:this.appName};
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData, this.getHeaders() );
  }

  updateEmailOrderMailInstance(oid:number, mailInstanceId:string){
    let endpoint: string = '/order/update/mailinstance';
    let bodyData = {oid:oid, mailInstance:mailInstanceId};
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData, this.getHeaders() );
  }

  transformEmailOrders(emailOrders:any[]){
    let transformedEmailOrders: any[];

    transformedEmailOrders = emailOrders.map((o: EmailOrderDetail) => {
      let transformed: any = {};
      transformed.source = 'email'; // important, to distinguish the navigation of order detail page
      transformed.order_id = o.oid;
      transformed.email_order_id = o.orderid;
      transformed.rescode = o.rescode || '';
      transformed.storefront_name = o.storefrontname || '';
      transformed.order_type = 'Email Service';
      transformed.product = o.packageName;
      transformed.category = o.productName;
      transformed.domain = o.domemail || '';
      transformed.date = o.dtorder;
      transformed.order_status = o.status;
      transformed.order_status_value = o.statusValue;
      transformed.payment_status = o.paymentStatus;
      transformed.payment_status_value = o.paymentStatusValue;
      transformed.tenant_domain = '';
      transformed.order_type = '';

      // always set to MYT
      let dtOrderAsStr = o.dtorder +'+0800'; 
      transformed.date = new Date(dtOrderAsStr).toISOString();

      return transformed;
    })

    return transformedEmailOrders;
  }

  updateEmailOrderReferenceNo(orderUpdateRequest: any) {
    let endpoint: string = '/order/update/referenceno';
    let bodyData = Object.assign({}, orderUpdateRequest);
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData, this.getHeaders() );
  }

  orderActionSearch(searchObj:any){
    let endpoint: string = '/order/orderaction';
    return this.httpClient.post(this.emailServiceApiUrl + endpoint, searchObj, this.getHeaders() );
  }

  updateOrderActionPaymentReferenceNo(data:any){
    let endpoint: string = '/order/update/orderaction-referenceno';
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, data, this.getHeaders() );

  }

}
