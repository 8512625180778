import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../service/notification.service'
import { StoreFrontService } from '../../service/store-front.service'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment';
import { ScrollUtil } from 'src/app/util/scroll-util';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;

@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html',
  styleUrls: ['./notification-edit.component.css']
})
export class NotificationEditComponent implements OnInit {
  minDate = undefined;
  alertMsg = 0;
  notificationId
  data
  countries = []
  selectedCountries = []
  selectedItem
  editNotifLoad = false

  publish_time = {
    date: {
      year: 0,
      month: 0,
      day: 0,
    },
    hour: 12,
    minute: 0,
    period: 'AM',
  }

  message = [];
  
	constructor(
		public notificationService:NotificationService,
		public route: ActivatedRoute,
		public storeFrontService:StoreFrontService,
    public router: Router
    ) { 
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    }

    
    ngOnInit() {
      this.notificationId = this.route.snapshot.paramMap.get('id')
      this.notificationService.getNotificationById(this.notificationId).subscribe(data=>{
        console.log(data)
        this.data = data["data"]
        
        this.publish_time.hour = (this.data["publish_time"].split(' ')[1]).split(':')[0];
        this.publish_time.minute = (this.data["publish_time"].split(' ')[1]).split(':')[1];
        this.publish_time.period = this.data["publish_time"].split(' ')[2];
  
        // let publishDate = new Date(this.data["publish_time"])
        // if (publishDate.getMonth() <9){
        // 	this.data.publish_time = `${publishDate.getFullYear()}-0${publishDate.getMonth()+1}-${publishDate.getDate()}`
        // } else {
        // 	this.data.publish_time = `${publishDate.getFullYear()}-${publishDate.getMonth()+1}-${publishDate.getDate()}`
        // }
  
        this.publish_time.date.year = parseInt(((this.data["publish_time"].split(' ')[0]).split('-')[0]));
        this.publish_time.date.month = parseInt((this.data["publish_time"].split(' ')[0]).split('-')[1]);
        this.publish_time.date.day = parseInt((this.data["publish_time"].split(' ')[0]).split('-')[2]);
  
        for (let [key, value] of Object.entries(this.data.target_groups)) {
          this.selectedCountries.push({id:key,name:value})
        }
  
        console.log(this.selectedCountries)
      })
      this.storeFrontService.getCountries().subscribe(resp => {
        this.countries = resp['data'];
        
      });
    }
    sendPressed(){
      this.closeAlertMsg();
      console.log(this.selectedCountries)
      this.data.selected_groups = []
      for (let i = 0; i < this.selectedCountries.length;i++){	
        this.data.selected_groups.push(this.selectedCountries[i]["id"])
      }
      this.data["notification_id"] = this.data["id"];
      let publish_time = moment(this.getDateTimestamp());
      this.data['publish_time'] = publish_time.format("YYYY-MM-DD hh:mm A");

      this.notificationService.editNotification(this.data).subscribe(resp=>{
        console.log(resp);
        this.editNotifLoad = true;
        this.alertMsg = 1;
        ScrollUtil.scrollToTop();

        setTimeout(() => {
          this.router.navigate(['/manage-notification'])
        }, 2000);

      }, err => {
        console.log(err);
        this.editNotifLoad = false;
        this.alertMsg = 2;

        if(err["error"]["error"]) {
          // Validation message from backend
          for(let error in err["error"]["error"]) {
            console.log('lal', err["error"]["error"][error][0]);
            this.message.push(`${err["error"]["error"][error][0]}`);
          }
        }
        ScrollUtil.scrollToTop();
      });
      
    }

    closeAlertMsg() {
      this.alertMsg = 0;
      this.message = [];
    }

    scrollToTop() {
      (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
      })();
    }
  
    getDateTimestamp(): string {
      let year = this.publish_time.date.year;
      let month = `${this.publish_time.date.month}`;
      let day = `${this.publish_time.date.day}`;
  
      let hour = `${this.publish_time.hour}`;
      let minute  = `${this.publish_time.minute}`;
      let period = this.publish_time.period;
  
      if(this.publish_time.date.month < 10) {
        month = `0${this.publish_time.date.month}`;
      }
  
      if(this.publish_time.date.day < 10) {
        day = `0${this.publish_time.date.day}`;
      }

      if(this.publish_time.hour < 10) {
        hour = `0${this.publish_time.hour}`;
      }
  
      if(Number(`${this.publish_time.minute}`)< 10) {
        minute = `0${this.publish_time.minute}`;
      }

      return `${year}-${month}-${day} ${hour}:${minute} ${period}`;
    }
  
    onSelectItem(event){
      let item = this.countries.filter(val=>{
        return val.id == this.selectedItem
      })
  
      delete item[0]['phonecode'];
      item[0].id = item[0].id.toString();
      
      if(this.containsCountry(item[0], this.selectedCountries)){
      } else {
        this.selectedCountries.push(item[0]);
        console.log(this.selectedCountries)
      }
      
    }
  
    containsCountry(obj, list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === obj.id) {
          return true;
        }
      }
    
      return false;
    }
  
    removeCountry(country){
      console.log("button clicked")
      this.selectedCountries.splice(this.selectedCountries.indexOf(country),1)
    }

}
