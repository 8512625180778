import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var appJS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'webnic-sf-admin';

  constructor(
    private router:Router
  ) {}

  ngOnInit() {
    appJS.init();
  }
}
