import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { tap } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root"
})

export class PushNotificationService {

  apiUrl = environment.apiUrl;
  mainApiUrl = environment.mainApiUrl;

  httpHeaders = {
    headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  };

  slug = "";
  profile = undefined;
  countryId = undefined;
  currentMessage = new BehaviorSubject(null);

  latestNotifications = new Subject<any>();
  allNotifications = new Subject<any>();

  latestNotifications$ = this.latestNotifications.asObservable();
  allNotifications$ = this.allNotifications.asObservable();

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private toastr: ToastrService,
    private http: HttpClient) {
      this.angularFireMessaging.messages.subscribe(
        // (_messaging: AngularFireMessaging) => {
        // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging); }
        );

      // if (this.userService.getUser()){
      //   this.userHttpService.getProfile(this.slug).subscribe(
      //     data => {
      //
      //       this.profile = data["data"];
      //       this.countryId = this.profile["customer"]["country"];
      //     },
      //     err => {
      //
      //     }
      //   );
      // }
  }

  getLatestNotifications() {
    return this.http.get(
      `${this.apiUrl}push-notifications/latest`, this.httpHeaders
    ).pipe(
      tap(data => {
        this.latestNotifications.next(data);
      })
    );
  }

  getAllNotifications() {
    return this.http.get(
      `${this.apiUrl}push-notifications`, this.httpHeaders
    ).pipe(
      tap(data => {
        this.allNotifications.next(data);
      })
    );
  }

  getNotificationById(id) {
    return this.http.get(
      `${this.apiUrl}push-notifications/${id}`, this.httpHeaders
    )
  }

  markNotificationAsRead(id) {
    let data = {};

    return this.http.post(
      `${this.apiUrl}push-notifications/${id}/mark-as-read`, data, this.httpHeaders
    )
  }

  /**
   * Subscribe the registered token to
   * push notification topic.
   * 
   * @param token token as value
   */
  subscribeToTopic(token) {
    let data = {
      fcm_token: token
    };

    return this.http.post(
      `${this.apiUrl}push-notifications/subscribe`, data, this.httpHeaders
    );
  }

  unsubscribeFromTopic(token) {
    let data = {
      fcm_token: token
    };

    return this.http.post(
      `${this.apiUrl}push-notifications/unsubscribe`, data, this.httpHeaders
    );
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcm-tokens/').update(data);
      }
    );
  }

  removeToken() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.angularFireAuth.authState.pipe(take(1)).subscribe(
          () => {
            this.angularFireDB.object(`fcm-tokens/${token}`).remove();
          }, err => {
            console.error('Failed to remove token.');
          }
        );
      }, (err) => {
        console.error('Failed to remove token.', err);
      }
    );
  }

  /**
   * request permission for notification from firebase cloud messaging
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // this.updateToken(token, token); // use token as userId since it's unique :shrug:

        this.subscribeToTopic(token).subscribe(data => {
        }, err => {
          console.error("Failed to subscribe to topic.");
        });
      }, (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        var targetGroups = payload['data']['target_groups'].split(", ");

        if (this.countryId !== undefined && targetGroups.includes(this.countryId)) {

            // TODO: Make sure all notifications requests finished first before showing toast

            // this.userHttpService.get5LatestNotification(this.slug).subscribe((data) => {
            // this.userHttpService.refreshLatestNotifications(data);
            // });

            // this.userHttpService.getNotification(this.slug).subscribe((data) => {
            // this.userHttpService.refreshAllNotifications(data);
            // });

            this.toastr.info(payload['data']['body'], payload['data']['title'], {
              timeOut: 10000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            });

            this.currentMessage.next(payload);
        }

        // TEST START -- Because get account info still doesn't return country or anything

        this.getLatestNotifications().subscribe();

        this.toastr.info(payload['data']['body'], payload['data']['title'], {
          timeOut: 10000,
          progressBar: true,
          positionClass: 'toast-bottom-right'
        });

        this.currentMessage.next(payload);
        // TEST END
    });
  }
}