<header class="app-header navbar">
  <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" (click)="toggleSidebar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item dropdown d-md-down-none">
      <a id="grid-btn" class="nav-link wave-animation float-right mr-3" style="cursor: pointer; transition: all 0.2s ease-in-out; width: fit-content;" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" (click)="clickGrid($event)">
        <i style="font-size: medium;" class="icon-grid"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-left dropdown-menu-lg" style="max-height: 80vh; overflow-y: auto;">
        <a class="dropdown-item" routerLink="/dashboard">Default Panel</a>
        <!-- <a class="dropdown-item" routerLink="/microsoftcsp">MicrosoftCSP Panel</a> -->
        <a class="dropdown-item" routerLink="/sectigoweb">Sectigoweb Panel</a>
        <a class="dropdown-item" routerLink="/promotion">Promotion Panel</a>
        <a class="dropdown-item" routerLink="/marketing">Marketing Panel</a>
        <a class="dropdown-item" routerLink="/email-notification">Email Notification Panel</a>
        <a class="dropdown-item" routerLink="/dns">DNS Panel</a>
        <a class="dropdown-item" routerLink="/whmcs">WHMCS Panel</a>
        <a class="dropdown-item" routerLink="/abuse">Abuse Automation</a>
        <a class="dropdown-item" routerLink="/product">Product Panel</a>
        <a class="dropdown-item" routerLink="/second-hand-domain">Secondhand Domain</a>
        <a class="dropdown-item" routerLink="/ssl">SSL</a>
        <a class="dropdown-item" routerLink="/namewrap">Namewrap</a>
        <a class="dropdown-item" routerLink="/mimining">Domain Backorder</a>
        <a class="dropdown-item" routerLink="/china-acceleration">China Acceleration</a>
      </div>
    </li>
  </ul>

  <a id="header-logo" class="navbar-brand" routerLink="/dashboard"></a>
  <div class="d-md-down-none">{{panel}}</div>

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown d-md-down-none">
      <a class="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
        <i class="icon-bell"></i><span class="badge badge-pill badge-danger">{{ unreadNotifications }}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg">
        <div class="dropdown-header text-center">
          <strong>You have {{ unreadNotifications }} notifications</strong>
        </div>
        <div *ngFor="let notification of latestNotifications" [ngSwitch]="notification['is_read']">
          <a *ngSwitchCase="true" (click)="clickNotification(notification)" class="dropdown-item notification-item">
            <i class="icon-chart text-info"></i> {{ notification['title'] }}
            <small>{{ notification['time_posted'] }}</small>
          </a>
          <a *ngSwitchCase="false" (click)="clickNotification(notification)" class="dropdown-item notification-item notification-item-unread">
            <i class="icon-chart text-info"></i> {{ notification['title'] }}
            <small>{{ notification['time_posted'] }}</small>
          </a>
        </div>
        <a routerLink="/notification-list" class="dropdown-item notification-all-btn text-center">
          <strong>View all notifications</strong>
        </a>
      </div>
    </li>

    <li class="nav-item dropdown d-lg-none">
      <a id="grid-btn-mobile" class="nav-link wave-animation" (click)="clickGrid($event)" style="cursor: pointer; transition: all 0.2s ease-in-out; width: fit-content;" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        <i style="font-size: medium;" class="icon-grid"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" style="max-height: 80vh; overflow-y: auto;">
        <a class="dropdown-item" routerLink="/dashboard">Default Panel</a>
        <!-- <a class="dropdown-item" routerLink="/microsoftcsp">MicrosoftCSP Panel</a> -->
        <a class="dropdown-item" routerLink="/sectigoweb">Sectigoweb Panel</a>
        <a class="dropdown-item" routerLink="/promotion">Promotion Panel</a>
        <a class="dropdown-item" routerLink="/marketing">Marketing Panel</a>
        <a class="dropdown-item" routerLink="/email-notification">Email Notification Panel</a>
        <a class="dropdown-item" routerLink="/dns">DNS Panel</a>
        <a class="dropdown-item" routerLink="/whmcs">WHMCS Panel</a>
        <a class="dropdown-item" routerLink="/abuse">Abuse Automation</a>
        <a class="dropdown-item" routerLink="/product">Product Panel</a>
        <a class="dropdown-item" routerLink="/second-hand-domain">Secondhand Domain</a>
        <a class="dropdown-item" routerLink="/ssl">SSL</a>
        <a class="dropdown-item" routerLink="/namewrap">Namewrap</a>
        <a class="dropdown-item" routerLink="/mimining">Domain Backorder</a>
        <a class="dropdown-item" routerLink="/china-acceleration">China Acceleration</a>

      </div>
    </li>

    <li class="nav-item dropdown d-md-down-none pr-5">
      <a class="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true"
         aria-expanded="false">Admin</a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-md">
        <div class="dropdown-header text-center">
          <!-- <a (click)="logoutPressed()" href="#"><strong>Logout</strong>> -->
          <form action="#">
            <button type="submit" class="btn btn-custom btn-login hvr-fade d-block m-auto"
                    (click)="logoutPressed()">Logout
              <i *ngIf="payBtnPressed" class="fa fa-spinner fa-spin"></i>
            </button>
          </form>
        </div>
      </div>
    </li>
  </ul>
</header>
<div class="modal fade" id="notificationModal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{selectedNotif?.title}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="selectedNotif?.content"></div>
        <div class="modal-footer">
            <button type="button" class="btn btn-custom-outline" data-bs-dismiss="modal">Dismiss</button>
        </div>
      </div>
  </div>
</div>