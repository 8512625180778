<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Home</li>
    <li class="breadcrumb-item active">Notification Management</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive py-2">
                <table id="table" class="table table-hover table-bordered datatable-dropdown-filter">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Snippet</th>
                      <th>Notification Type</th>
                      <th>Publish Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="clickable-row" (click)="edit(notif.id)" *ngFor="let notif of notifications">
                      <td>{{notif.title}}</td>
                      <td>{{notif.snippet}}</td>
                      <!-- <td>{{notif.type}}</td> -->
                      <td>{{notif.type == 1 ? 'Info' : (notif.type == 2 ? 'Deeplink' : '')}}</td>
                      <td>{{notif.publish_time}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Title</th>
                      <th>Snippet</th>
                      <th>Notification Type</th>
                      <th>Publish Time</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</main>
