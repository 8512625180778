import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router:Router,
    private authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (!localStorage.getItem('access_token')) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
      }else{
        this.authService.checkAuth(localStorage.getItem('access_token')).subscribe( res => {
          if(res['data'] != true)
          {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
          }
        }, err => {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
          return false;
        })
        return true;
      }
  }
}
