import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from 'src/app/shared/push-notification.service';

declare var $: any;

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})

export class NotificationListComponent implements OnInit {

  dataTable:any;
  allNotifications;
  unreadNotifications;
	selectedNotification;

  constructor(
    private pushNotificationService: PushNotificationService
  ) { }

  getAllNotifications(): void {
    this.pushNotificationService.allNotifications$.subscribe((data) => {
			this.allNotifications = data["data"]["notifications"];
      this.unreadNotifications = data["data"]["unread_count"];
		});
  }

  clickNotification(notification) {
		this.selectedNotification = notification;

		this.pushNotificationService.markNotificationAsRead(notification['id']).subscribe(data => {
		  if(notification['type'] == "2") {
				window.open(notification['targeted_page'], '_blank');
		  } else {
				$('#viewNotification').modal('show');
		  }

		  this.pushNotificationService.getAllNotifications().subscribe();
		}, err => {
		});
	}

  ngOnInit() {
    this.getAllNotifications();
    this.pushNotificationService.getAllNotifications().subscribe();
  }

}
