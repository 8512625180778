<body id="actual-body" class="app header-fixed sidebar-fixed aside-menu-fixed aside-menu-hidden">
  <app-header></app-header>
  <div class="app-body">
    <app-sidebar></app-sidebar>
    <!-- Component Masuk Sini -->
    <div style="width: 100%;">
      <router-outlet></router-outlet>        
    </div>
  </div>

  <footer class="app-footer">
    <span>© 2018 Web Commerce Communications Ltd. All rights reserved</span>
    <span class="ml-auto">Powered by <a href="https://www.webnic.cc/">WebNIC</a></span>
  </footer>
</body>