

    <main id="manage-order" class="main">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item active">Order Management</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="table-responsive col">
                      <table id="orderDataTable" class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>Partner ID</th>
                            <th>Products</th>
                            <th>Product Category</th>
                            <th>Storefront</th>
                            <th>Order ID</th>
                            <th>
                              <div>Order Status</div>
                              <div>Payment Status</div>
                            </th>
                            <th>Order Type</th>
                            <th>Order Date</th>
                            <th>Domain</th>
                            <th>Tenant Domain</th>
                          </tr>
                        </thead>
                        <thead id="table-filter" *ngIf="!orderLoading" style="display: table-row-group;">
                          <tr>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th class="hidden-xs hidden-sm"></th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                            <th>
                              <input type="text" placeholder="Filter here" style="width: 100%;">
                            </th>
                          </tr>
                        </thead>
                        <tbody id="table-data">
                          <td colspan="10" style="text-align: center" [hidden]="!orderLoading">Loading...</td>
                          <tr class="clickable-row" *ngFor="let order of orders" (click)="show(order.order_id,order.source)">
                            <td>{{order.rescode}}</td>
                            <td>{{order.product}}</td>
                            <td>{{order.category}}</td>
                            <td>{{order.storefront_name}}</td>
                            <td>
                              <div [ngSwitch]="order.source">
                                <span *ngSwitchCase="'email'">{{order.email_order_id}}</span>
                                <span *ngSwitchDefault>{{order.order_id}}</span>
                              </div>
                            </td>
                            <td>
                              <div [ngSwitch]="order.source">
                                <span *ngSwitchCase="'email'" class="badge" [ngClass]="{
                                    'badge-success': order.order_status == 'NEW',
                                    'badge-warning': order.order_status == 'PENDING' || order.order_status == 'EXPIRING' ||  order.order_status == 'NOEMAILDOMAIN',
                                    'badge-secondary': order.order_status == 'ACTIVE',
                                    'badge-danger': order.order_status == 'EXPIRED' || order.order_status == 'CANCEL'
                                    }">
                                {{order.order_status_value}}
                                </span>
                                <span *ngSwitchDefault class="badge" [ngClass]="{
                                  'badge-success': order.order_status == 'New',
                                  'badge-warning': order.order_status == 'Processing' || order.order_status == 'In Progress',
                                  'badge-secondary': order.order_status == 'Done' ||  order.order_status == 'Success',
                                  'badge-danger': order.order_status == 'Rejected'
                                  }">
                                {{order.order_status}}
                                </span>
                              </div>
                              <div [ngSwitch]="order.source">
                                <span *ngSwitchCase="'email'" class="badge" [ngClass]="{
                                  'badge-success': order.payment_status == 'PENDING',
                                  'badge-secondary': order.payment_status == 'COMPLETE' || order.payment_status == 'REFUND'
                                  }">
                                {{order.payment_status_value}}
                                </span>
                                <span *ngSwitchDefault class="badge" [ngClass]="{
                                  'badge-success': order.payment_status == 'Pending',
                                  'badge-warning': order.payment_status == 'Payment not made',
                                  'badge-secondary': order.payment_status == 'Success' ||  order.payment_status == 'Refunded',
                                  'badge-danger': order.payment_status == 'Fail'
                                  }">
                                {{order.payment_status}}
                                </span>
                              </div>
                            </td>
                            <td>{{order.order_type}}</td>
                            <td>{{order.date}}</td>
                            <td>{{order.domain}}</td>
                            <td>{{order.tenant_domain}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Partner ID</th>
                            <th>Products</th>
                            <th>Product Category</th>
                            <th>Storefront</th>
                            <th>Order ID</th>
                            <th>
                              <div>Order Status</div>
                              <div>Payment Status</div>
                            </th>
                            <th>Order Type</th>
                            <th>Order Date</th>
                            <th>Domain</th>
                            <th>Tenant Domain</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>

