import { map, catchError } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderService } from 'src/app/service/order.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'datatables.net';
import 'datatables.net-bs4';
import { EmailService } from 'src/app/service/email.service';
import { forkJoin, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.css']
})
export class OrderManagementComponent implements OnInit {

  orderLoading: boolean = true;

  orders:any[];
  dataTable:any;

  o365Orders$: Observable<any[]>;
  emailOrders$: Observable<any[]>;

  handleError : any = (error:any) => {
    console.error(error);
    return of([]); //return observable of empty array
  };

  constructor(
    private router:Router,
    private orderService:OrderService,
    private chRef: ChangeDetectorRef,
    private emailService: EmailService,
  ) { }

  ngOnInit() {
    //this.getOrderByReseller();

    this.o365Orders$ = this.orderService.getAllOrders().pipe(map((res:any)=>{
      return this.transformO365Orders(res.data.orders);
    }),catchError(this.handleError));

    this.emailOrders$ = this.emailService.getEmailOrders().pipe(map((res:any)=>{
      return this.emailService.transformEmailOrders(res.orders);
    }),catchError(this.handleError));


    this.getOrders().subscribe((res: any[]) => {
      this.orders = res;
      this.orderLoading = false;
      this.chRef.detectChanges();
      const table: any = $('#orderDataTable');
      this.dataTable = table.DataTable({
        order: [7, "desc"], // default sort by date desc
        lengthMenu: [20, 30, 50, 75, 100],
        columnDefs: [
          { name: "rescode", targets: 0 },
          { name: "product", targets: 1 },
          { name: "category", targets: 2 },
          { name: "storefrontname", targets: 3 },
          { name: "orderid", targets: 4 },
          { name: "orderstatus", targets: 5 },
          { name: "paymentstatus", targets: 6 },
          { 
            name: "date",
            targets: 7 ,
            render:function(data){
              if(data && data!=null && data!=''){
                return moment(data).format("YYYY-MM-DD HH:mm:ss");
              }else{
                return '';
              }
            }
          },
          { name: "domainname", targets: 8 },
          { name: "tenant_domain", targets: 9}
        ]
      });

      this.initTableFilters();
    });


  }

  initTableFilters() {

    // find each table filter
    $('#table-filter tr th').each((index, element) => {
      
      // bind all text input search with its column 
      $('input', element).on('keyup change clear', (event: any) => {
        let value = event.target.value;
        if (this.dataTable.column(index).search() !== value) {
          this.dataTable
            .column(index)
            .search(value)
            .draw();
        }
      });

    });
  }

  transformO365Orders(o365orders:any[]){

    o365orders.forEach(o=>{
      if(o.date && o.date !=null && o.date !=''){
         // always set to MYT
        let dateAsStr = o.date + '+0800';
        o.date = new Date(dateAsStr).toISOString();
      }
      if (o.storefront && o.storefront != null && o.storefront != '') {
        o.storefront_name = o.storefront;
      }
    })

    return o365orders;
  }

  show(id:any,source?:string){
    if(source && source == 'email'){
      this.router.navigateByUrl('/email/order-detail/'+id); // email
    }else {
      this.router.navigateByUrl('/order/'+id); // o365
    }
  }

  // getOrderByReseller(){
  //   this.orderService.getAllOrders().subscribe(
  //     result => {
  //       this.orders = result['data']['orders']
  //       this.chRef.detectChanges();
  //       const table: any = $('table');
  //       this.dataTable = table.DataTable()
  //   }, error => {
  //     console.log(error)
  //   })
  // }

  getOrders() {
    // get orders from multiple observable sources
    // if one of the sources failed to fetch data, the observable of empty array will returned as defined in catchError
    return forkJoin(this.o365Orders$,this.emailOrders$).pipe(map(([res1,res2])=>{return [...res1,...res2];}))
  }
}
