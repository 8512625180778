<main id="manage-order" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Home</li>
    <li class="breadcrumb-item">Order Management</li>
    <li class="breadcrumb-item active">Order Details</li>
  </ol>

  <div id="myAlert" class="alert-container" style="margin-top: -35px; padding-top: 10px" *ngIf="successMessage">
    <div class="alert alert-custom alert-success">
      {{ successMessage }}
      <button type="button" class="close" (click)="closeAlertMsgSuccess()">×</button>
    </div>
    <br>
  </div>
  <div id="myAlert" class="alert-container" style="margin-top: -35px; padding-top: 10px" *ngIf="errorMessage">
    <div class="alert alert-custom alert-danger">
      <button type="button" class="close" (click)="closeAlertMsgError()">×</button>
      {{ errorMessage }}
    </div>
    <br>
  </div>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">
        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">Order Information</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td>Product</td>
                  <td>{{order.order_items[0].product}}</td>
                </tr>
                <tr>
                  <td>Product Category</td>
                  <td>{{order.category}}</td>
                </tr>
                <tr>
                  <td>Order Id</td>
                  <td>{{order.order_id}}</td>
                </tr>
                <tr>
                  <td>Partner Id</td>
                  <td>{{order.rescode}}</td>
                </tr>
                <tr>
                  <td>Company Name</td>
                  <td>{{order.order_items[0].item_detail.company_name}}</td>
                </tr>
                <tr>
                  <td>Tenant Domain</td>
                  <td>{{order.order_items[0].item_detail.tenant_domain}}</td>
                </tr>
                <tr>
                  <td>Creation Date</td>
                  <td>{{order.order_date}}</td>
                </tr>
                <tr>
                  <td>Last Modified Date</td>
                  <td>{{order.order_date}}</td>
                </tr>
                <tr>
                  <td>License Count</td>
                  <td>{{order.order_items[0].license_count}}</td>
                </tr>
                <tr>
                  <td>Subscribed Month</td>
                  <td>{{order.order_items[0].subscribed_month}}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{orderStatus}}</td>
                </tr>
                <tr *ngIf="storefront">
                  <td>Order From</td>
                  <td>{{storefront.storefront_name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-5">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">Subscription Information</th>
                </tr>
              </thead>
              <tbody class="bg-white">

                <tr>
                  <td>Subscription Id</td>
                  <td>{{subscription?.subscription_id}}</td>
                </tr>
                <tr>
                  <td>Total license</td>
                  <td>{{subscription?.license_count}}</td>
                </tr>
                <tr>
                  <td>Expiry Date</td>
                  <td>{{subscription?.dtend}}</td>
                </tr>
                <tr>
                  <td>Auto Renew Enabled</td>
                  <td>{{subscription?.is_auto_renew ? "Yes" : "No"}}</td>
                </tr>
                <tr>
                  <td>Subscription Status</td>
                  <td>{{subscription?.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="3">Order Process</th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr>
                  <td>Payment Method</td>
                  <td colspan="2" *ngIf="order.payment_details.length > 0">
                    {{order.payment_details[order.payment_details.length - 1].paymethod_type}}</td>
                  <td colspan="2" *ngIf="order.payment_details.length == 0">N/A</td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td *ngIf="order.payment_details.length > 0">
                    {{order.payment_details[order.payment_details.length - 1].status}}
                  </td>
                  <td *ngIf="order.payment_details.length == 0">
                    No payment made.
                  </td>
                  <td>
                    <div class="justify-content-center d-flex">
                      <!-- <button *ngIf="order.payment_details.length > 0" type="submit"
                                                            class="btn btn-block btn-primary"
                                                            (click)="paymentDone(order.order_id)">Mark as Done</button> -->
                      <button [disabled]="paymentDoneButtonPressed" type="submit" (click)="paymentDone(order.order_id)" class="btn btn-block btn-primary">Mark as Done</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td>{{order.status}}</td>
                  <td>
                    <div class="justify-content-center d-flex">
                      <button [disabled]="order.status == 'Success'" class="btn btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#order-dialog">Proceed Order</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Refund Status</td>
                  <!-- <td *ngIf="order.order_items[0].refundable != 0">
                    <p class="mt-2 mb-0 reason-caption">Reason:</p>
                    <p class="mb-0 reason-caption">#</p>
                  </td> -->
                  <td colspan="2" *ngIf="order.order_items[0].refundable == 0">No</td>
                  <td colspan="2" *ngIf="order.order_items[0].refundable == 1">
                    <div class="justify-content-center d-flex">
                      <button class="btn btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#refund-dialog">Proceed Refund</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="float-right">
            <a routerLink="/orders" class="btn btn-dark btn-lg px-5 mb-4">Back</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
  <!-- Modal -->
  <div class="modal fade pr-0" id="order-dialog" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to proceed the order?</p>
        </div>
        <div class="modal-footer">
          <input type="hidden" name="sf_id" value="">
          <input type="hidden" name="sf_user_id" value="">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button [disabled]="proceedButtonPressed" type="submit" (click)="proceedOrder(order.order_id, order.sf_id, order.sf_user_id)" class="btn btn-dark">Proceed</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade pr-0" id="refund-dialog" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to refund the order?</p>

          <div class="form-group mt-2">
            <label>Reason</label>
            <textarea [(ngModel)]="refundReason" id="textarea-input" name="textarea-input" rows="5" class="form-control form-control-sm"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button [disabled]="refundButtonPressed || refundReason.trim() == ''" (click)="refundOrder()" class="btn btn-dark">Proceed</button>
        </div>
      </div>
    </div>
  </div>
</main>
