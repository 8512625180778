// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://backend.ezyicp.com/api/wnadmin/',
  mainApiUrl: 'https://backend.ezyicp.com/api/',
  firebase: {
    apiKey: "AIzaSyBcMMxMcfHnq8Nt7sYTakKu2nMzkVE50jY",
    authDomain: "webnic-storefront-client.firebaseapp.com",
    databaseURL: "https://webnic-storefront-client.firebaseio.com",
    projectId: "webnic-storefront-client",
    storageBucket: "webnic-storefront-client.appspot.com",
    messagingSenderId: "413776481219",
    appId: "1:184417528161:web:7d319a78fb4cc9d7"
  },

  emailService:{
    appName: 'WEBNIC_ADMIN',
    apiUrl: 'https://sitapi.webnic.cc/emailservice/api'
  },

  sectigoweb:{
    apiUrl: 'https://sitapi.webnic.cc/sectigoweb/api',
  },

  promotion:{
    apiUrl: 'https://sitapi.webnic.cc/promotion/api',
  },

  mailService:{
    apiUrl: 'https://sitapi.webnic.cc/mail/api',
    // apiUrl: 'http://localhost:8083/mail/api',
  },

  webnicNodeJSApi: {
    apiUrl: 'https://analytics-bot.webnic.cc'
  },

  dnsService: {
    apiUrl: "https://sitapi.webnic.cc/dns"
    // apiUrl: 'http://localhost:9053/dns'
  },

  cronService: {
    apiUrl: 'https://sitapi.webnic.cc/abuse-automation'
    // apiUrl: 'http://localhost:9996/abuse-automation'
  },

  domainService: {
    apiUrl: 'https://sitapi.webnic.cc/domain'
    // apiUrl: 'http://localhost:8080/domain'
  },

  whmcsService: {
    apiUrl: 'https://sitapi.webnic.cc/whmcs/v1'
    //apiUrl: 'http://localhost:8080/whmcs/v1'
  },

  sslService: {
    apiUrl: 'https://sitapi.webnic.cc/ssl/v2'
    //apiUrl: 'http://localhost:9443/ssl/v2'
  },

  sslcrawlerService: {
    apiUrl: 'https://sitapi.webnic.cc/sslcrawler/v2'
    //apiUrl: 'http://localhost:8080/sslcrawler/v2'
  },

  namewrapService: {
    apiUrl: "https://sitapi.webnic.cc/namewrap"
    //apiUrl: "http://localhost:8080/namewrap"
  },

  miminingService: {
    apiUrl: "https://sitapi.webnic.cc/mimining/v1/admin"
    //apiUrl: 'http://localhost:8080/mimining/v1/admin'
  },
  
  productService: {
    apiUrl: "https://sitapi.webnic.cc/product/v1/admin"
    //apiUrl: 'http://localhost:8080/product/v1/admin'
  },

  cnAcceleration: {
    apiUrl: 'https://sitapi.webnic.cc/cnacceleration/v1/admin',
    // apiUrl: 'http://localhost:8080/cnacceleration/v1/admin'
  },

  reseller: {
    apiUrl: 'https://sitapi.webnic.cc/reseller',
    //apiUrl: 'http://localhost:8080/reseller'
  },

  disableCaptcha: true,

  hCaptchaSiteKey: 'd2a7d53a-a752-442e-9385-a25f3abee307',

  managerUsers: ["tec-ethanik762", "testmailmanager", "tec-dicky4029", "mktg-trish", "mktg-saywei"]

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
