
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from 'src/app/service/email.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { EmailOrderDetail } from 'src/app/model/email/email-order-detail.model';
import { EmailOrderAction } from 'src/app/model/email/email-order-action.model';
import { ScrollUtil } from 'src/app/util/scroll-util';

@Component({
  selector: 'app-email-order-detail',
  templateUrl: './email-order-detail.component.html',
  styleUrls: ['./email-order-detail.component.css']
})
export class EmailOrderDetailComponent implements OnInit {

  oid:number;
  order: EmailOrderDetail;

  successCheck:boolean = false;
  successMessage:string;
  errorCheck:boolean = false;
  errors: string[] = [];

  modifyStatusForm:UntypedFormGroup;
  modifyMailInstanceForm:UntypedFormGroup;
  modifyPaymentStatusForm: UntypedFormGroup;

  modifyStatusBtnPressed:boolean = false;
  modifyMailInstanceBtnPressed:boolean = false;
  completeOrderBtnPressed:boolean = false;
  cancelOrderBtnPressed:boolean = false;
  modifyPaymentStatusBtnPressed: boolean = false;

  enableModifyStatus:boolean = false;
  enableModifyMailInstance:boolean = false;
  enableCompleteOrder:boolean = false;
  enableCancelOrder:boolean = false;
  enableModifyPaymentStatus: boolean = false;

  modalRef: BsModalRef;

  orderActions: EmailOrderAction[] = null;

  pendingPaymentAddLicensesOAList: EmailOrderAction[] = [];
  enableModifyAddLicensesOAPaymentStatus: boolean = false;
  modifyAddLicensesOAPaymentStatusForm: UntypedFormGroup;
  modifyAddLicensesOAPaymentStatusBtnPressed: boolean = false;
  
  constructor(private route: ActivatedRoute,
    private emailService:EmailService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder) { }

    ngOnInit() {
      this.route.params.subscribe(
        params => {
            this.oid = +params['id'];
            this.getOrderDetails();
        }
      );

    }

    getOrderDetails(){
      this.emailService.getEmailOrderDetails(this.oid).subscribe((res:any)=>{
          this.order = res;
          this.checkAllowedAction();
          this.checkSpecialOrderActions();
      },err=>{
          this.handleError(err);
      });
    }

    checkAllowedAction(){
      // reset
      this.enableModifyStatus = false;
      this.enableModifyMailInstance = false;
      this.enableCompleteOrder = false;
      this.enableCancelOrder = false;
      this.enableModifyPaymentStatus = false;

      let status = this.order.status;
      let paymentStatus = this.order.paymentStatus;

      if(paymentStatus === 'PENDING') {
        this.enableModifyPaymentStatus = true;
        return;
      }
      if(status == 'NEW' || status == 'PENDING'){
        this.enableModifyStatus = true;
        this.enableModifyMailInstance = true;
        this.enableCancelOrder = true;
        this.enableCompleteOrder = true;
      }else if(status == 'ACTIVE'){
        this.enableModifyMailInstance = true;
      }else if(status == 'EXPIRED'){
        this.enableModifyMailInstance = true;
      }
    }

    async checkSpecialOrderActions(){
      let status = this.order.status;

      // check for order actions which payment status is 'Pending'
      if(status == 'ACTIVE'){
        let searchData : any = {
          oid: this.order.oid.toString(),
          paymentStatus: 'PENDING'
        }

        await this.emailService.orderActionSearch(searchData).toPromise().then((res:any)=>{
          this.orderActions = res;
        }).catch(err=>{
          console.error(err);
        });

        if(this.orderActions && this.orderActions.length > 0 ){

          // add licenses order actions
          this.pendingPaymentAddLicensesOAList = this.orderActions.filter(oa=>oa.action == 'ADDLICENSE');
          if(this.pendingPaymentAddLicensesOAList.length>0){
            // enable option to update add licenses order action payment status if exists
            this.enableModifyAddLicensesOAPaymentStatus = true;
          }

        }
      }
      
    }

    openModifyStatusModal(template: TemplateRef<any>) {
      this.modifyStatusBtnPressed = true;

      if (!this.modifyStatusForm) {
        this.modifyStatusForm = this.formBuilder.group({
          status: [this.order.status, [Validators.required,this.validateAllowedUpdateStatus]]
        });
      }
      this.openModal(template);
      this.modifyStatusBtnPressed = false;
    }

    get statusFormControl(){
      return this.modifyStatusForm.get('status');
    }

    modifyStatusPressed(){
      this.modifyStatusBtnPressed = true;
      this.errorCheck = false;
      this.successCheck = false;

      if(this.modifyStatusForm.invalid){
        this.modifyStatusBtnPressed = false;
        return;
      }
      let status:string = this.statusFormControl.value;

      this.emailService.updateEmailOrderStatus(this.oid,status).subscribe((res:any)=>{
        let status:boolean = res.status;
        if(status){
          this.successMessage = res.message;
          this.successCheck = true;
        }else{
          if(res.message){
            this.errors.push(res.message);
          }
          if(res.errors){
            let errors:any[] = res.errors;
            errors.forEach(e=>this.errors.push(e.message));
          }
          this.errorCheck = true;
        }
        this.getOrderDetails();
        this.modifyStatusBtnPressed = false;
        this.modalRef.hide();
        ScrollUtil.scrollToTop();
      },err=>{
        this.modifyStatusBtnPressed = false;
        this.modalRef.hide();
        this.handleError(err);

      });
    }

    openModifyMailInstanceModal(template: TemplateRef<any>) {
      this.modifyMailInstanceBtnPressed = true;

      if (!this.modifyMailInstanceForm) {
        this.modifyMailInstanceForm = this.formBuilder.group({
          instanceId: ['', Validators.required]
        });
      }
      this.openModal(template);
      this.modifyMailInstanceBtnPressed = false;
    }

    get instanceIdFormControl(){
      return this.modifyMailInstanceForm.get('instanceId');
    }

    modifyMailInstancePressed(){
      this.modifyMailInstanceBtnPressed = true;
      this.errorCheck = false;
      this.successCheck = false;

      if(this.modifyMailInstanceForm.invalid){
        this.modifyMailInstanceBtnPressed = false;
        return;
      }
      let instanceId:string = this.instanceIdFormControl.value;

      this.emailService.updateEmailOrderMailInstance(this.oid,instanceId).subscribe((res:any)=>{
        let status:boolean = res.status;
        if(status){
          this.successMessage = res.message;
          this.successCheck = true;
        }else{
          if(res.message){
            this.errors.push(res.message);
          }
          if(res.errors){
            let errors:any[] = res.errors;
            errors.forEach(e=>this.errors.push(e.message));
          }
          this.errorCheck = true;
        }
        this.getOrderDetails();
        this.modifyMailInstanceBtnPressed = false;
        this.modalRef.hide();
        ScrollUtil.scrollToTop();
      },err=>{
        this.modifyMailInstanceBtnPressed = false;
        this.modalRef.hide();
        this.handleError(err);

      });
    }

    openCancelOrderModal(template: TemplateRef<any>) {
      this.cancelOrderBtnPressed = true;

      this.openModal(template);

      this.cancelOrderBtnPressed = false;
    }

    cancelOrderConfirmationPressed(){
      this.cancelOrderBtnPressed = true;
      this.errorCheck = false;
      this.successCheck = false;

      let status:string = 'CANCEL';

      this.emailService.updateEmailOrderStatus(this.oid,status).subscribe((res:any)=>{
        let status:boolean = res.status;
        if(status){
          this.successMessage = res.message;
          this.successCheck = true;
        }else{
          if(res.message){
            this.errors.push(res.message);
          }
          if(res.errors){
            let errors:any[] = res.errors;
            errors.forEach(e=>this.errors.push(e.message));
          }
          this.errorCheck = true;
        }
        this.getOrderDetails();
        this.cancelOrderBtnPressed = false;
        this.modalRef.hide();
        ScrollUtil.scrollToTop();
      },err=>{
        this.cancelOrderBtnPressed = false;
        this.modalRef.hide();
        this.handleError(err);
      });
    }

    openCompleteOrderModal(template: TemplateRef<any>) {
      this.completeOrderBtnPressed = true;

      this.openModal(template);

      this.completeOrderBtnPressed = false;
    }


    completeOrderConfirmationPressed(){
      this.completeOrderBtnPressed = true;
      this.errorCheck = false;
      this.successCheck = false;

      let status:string = 'ACTIVE';

      this.emailService.updateEmailOrderStatus(this.oid,status).subscribe((res:any)=>{
        let status:boolean = res.status;
        if(status){
          this.successMessage = res.message;
          this.successCheck = true;
        }else{
          if(res.message){
            this.errors.push(res.message);
          }
          if(res.errors){
            let errors:any[] = res.errors;
            errors.forEach(e=>this.errors.push(e.message));
          }
          this.errorCheck = true;
        }
        this.getOrderDetails();
        this.completeOrderBtnPressed = false;
        this.modalRef.hide();
        ScrollUtil.scrollToTop();
      },err=>{
        this.completeOrderBtnPressed = false;
        this.modalRef.hide();
        this.handleError(err);
      });
    }

    openModifyPaymentStatusModal(template: TemplateRef<any>) {
      this.modifyPaymentStatusBtnPressed = true;

      if (!this.modifyPaymentStatusForm) {
        this.modifyPaymentStatusForm = this.formBuilder.group({
          referenceno: [{value: this.order.referenceno, disabled: !this.validateAllowedUpdatePaymentStatus}, [Validators.required]]
        });
      }
      this.openModal(template);
      this.modifyPaymentStatusBtnPressed = false;
    }

    get paymentStatusFormControl(){
      return this.modifyPaymentStatusForm.get('paymentStatus');
    }

    get referencenoFormControl() {
      return this.modifyPaymentStatusForm.get('referenceno');
    }

    modifyPaymentStatusPressed(){
      this.modifyPaymentStatusBtnPressed = true;
      this.errorCheck = false;
      this.successCheck = false;

      if(this.modifyPaymentStatusForm.invalid) {
        this.modifyPaymentStatusBtnPressed = false;
        return;
      }

      let orderUpdateRequest: any = {
        oid: this.oid,
        referenceno: this.referencenoFormControl.value,
        paymentmethodId: '2',
        paymentmethodName: 'Bank Transfer', // ** important, currently hardcoded always use 'Bank Transfer'
        failureReason: '', // set empty string will trigger update payment status to 'Complete'
      };

      this.emailService.updateEmailOrderReferenceNo(orderUpdateRequest).subscribe((res: any) => {
        let status: boolean = res.status;
        if(status){
          this.successMessage = res.message;
          this.successCheck = true;
        } else {
          if(res.message){
            this.errors.push(res.message);
          }
          if(res.errors){
            let errors: any[] = res.errors;
            errors.forEach(e => this.errors.push(e.message));
          }
          this.errorCheck = true;
        }
        this.getOrderDetails();
        this.modifyPaymentStatusBtnPressed = false;
        this.modalRef.hide();
        ScrollUtil.scrollToTop();
      }, err => {
        this.modifyPaymentStatusBtnPressed = false;
        this.modalRef.hide();
        this.handleError(err);
      });
    }

    openModifyAddLicensesOAPaymentStatusModal(template: TemplateRef<any>) {
      this.modifyAddLicensesOAPaymentStatusBtnPressed = true;

      if (!this.modifyAddLicensesOAPaymentStatusForm) {
        this.modifyAddLicensesOAPaymentStatusForm = this.formBuilder.group({
          oaid: [null, [Validators.required] ],
          referenceno: ['', [Validators.required] ]
        });
      }
      this.openModal(template);
      this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
    }

    get oaReferencenoFormControl() {
      return this.modifyAddLicensesOAPaymentStatusForm.get('referenceno');
    }

    modifyAddLicensesOAPaymentStatusPressed(){
      this.modifyAddLicensesOAPaymentStatusBtnPressed = true;
      this.errorCheck = false;
      this.successCheck = false;

      if(this.modifyAddLicensesOAPaymentStatusForm.invalid) {
        this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
        return;
      }

      console.log(this.modifyAddLicensesOAPaymentStatusForm.value);
      
      let updateRequest: any = {
        oid: this.order.oid.toString(),
        oaid: this.modifyAddLicensesOAPaymentStatusForm.value.oaid,
        referenceno: this.modifyAddLicensesOAPaymentStatusForm.value.referenceno,
        paymentmethodname: 'Bank Transfer',
        markPaymentAsComplete: true
      }

      this.emailService.updateOrderActionPaymentReferenceNo(updateRequest).subscribe((res: any) => {
        console.log(res);
        let status: boolean = res.status;
        if(status){
          this.successMessage = res.message;
          this.successCheck = true;
        } else {
          if(res.message){
            this.errors.push(res.message);
          }
          if(res.errors){
            let errors: any[] = res.errors;
            errors.forEach(e => this.errors.push(e.message));
          }
          this.errorCheck = true;
        }

        // reset orderActions
        this.orderActions.length = 0;
        this.pendingPaymentAddLicensesOAList.length = 0;
        this.enableModifyAddLicensesOAPaymentStatus = false;

        this.getOrderDetails();
        this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
        this.modalRef.hide();
        this.modifyAddLicensesOAPaymentStatusForm.reset();
        ScrollUtil.scrollToTop();
      }, err => {
        this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
        this.modalRef.hide();
        this.modifyAddLicensesOAPaymentStatusForm.reset();
        this.handleError(err);
      });
      
    }

    openModal(template: TemplateRef<any>, size?:string) {
      if(this.modalRef){
        this.modalRef.hide();
      }
      let modalConfig:any = {
        backdrop: true,
        ignoreBackdropClick: true
      };
      if(size && size === 'lg'){
        modalConfig.class = 'modal-lg';
      }
      this.modalRef = this.modalService.show(template, modalConfig);
    }

    handleError(err:any) {
      this.errors.length = 0;
      if (err.error instanceof ErrorEvent) {
        // client-side error
        let errorMessage = `Error: ${err.error.message}`;
        this.errors.push(errorMessage);
      } else {
        // server-side error
        let responseBodyErrors: any[] = null;

        if(err.error && err.error.errors){
          responseBodyErrors  = err.error.errors;
        }
        
        if (responseBodyErrors && responseBodyErrors.length>0) {
          this.errors = responseBodyErrors.map(e=> e.message).filter(msg => msg && msg != '' );
        }else{
          if(err.status == 0) {
            this.errors.push('Connection error. Please try again later.');
          }else{
            this.errors.push(`${err.message}`);
          }
        }
      }
      this.errorCheck = true;
      ScrollUtil.scrollToTop();
    }

    scrollToTop() {
      (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
      })();
    }

    validateAllowedUpdateStatus(control: AbstractControl) {
      if(control.value != 'NEW' && control.value != 'PENDING') {
        return { invalidUpdateStatus: true };
      }
      return null;
    }

    validateAllowedUpdatePaymentStatus(control: AbstractControl) {
      if(control.value !== 'PENDING') {
        return { invalidUpdatePaymentStatus: true };
      }
      return null;
    }

    closeErrorPressed(){
      this.errorCheck = false;
      this.errors.length = 0;
    }
    closeSuccessPressed(){
      this.successCheck = false;
      this.successMessage = '';
    }

}
