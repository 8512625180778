import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Statics } from 'src/app/util/statics';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  errorMessage;

  captchaSiteKey:string = Statics.captchaSiteKey;
  disableCaptcha = environment.disableCaptcha;

  validation_messages = {
    'username': [
      { type: 'required', message: 'Username is required' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 6 characters' }
    ],
    'captcha': [
      {type: 'required', message: 'Captcha is required'}
    ]
  };

  returnUrl: string = null;

  loading:boolean = false;
  setLoadingToFalse = () => {this.loading = false}

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    if (this.disableCaptcha){
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', [Validators.required,Validators.minLength(6)]],
      });
    }else{
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', [Validators.required,Validators.minLength(6)]],
        captcha: ['', Validators.required]
      });
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || null ;
    });
  }

  get loginCaptchaFormControl() {
    return this.loginForm.get('captcha');
  }

  login() {
    let data:any = this.loginForm.value;

    if(this.returnUrl && this.returnUrl != null){
      data.returnUrl = this.returnUrl;
    }
    this.loading = true;
    this.authService.postLogin(data).pipe(finalize(this.setLoadingToFalse)).subscribe(
      result => {
        localStorage.setItem('access_token', result['access_token']);

        let returnUrl = data.returnUrl;
        if(returnUrl && returnUrl != null){
          this.router.navigateByUrl(returnUrl);
        }else{
          this.router.navigate(["/dashboard"]);
        }

    }, (error: any) => {
      this.resetCaptcha();
      console.log(error);
      this.errorMessage = error['error']['message'];

      // 503 Service Unavailable
      if (error.status === 503) {
        this.errorMessage = "Service is currently unavailable. Please try again later.";
      }
    });
  }

  resetCaptcha() {
    this.loginCaptchaFormControl.patchValue(null);
  }

  onExpired(response: any) {
    this.resetCaptcha();
  }

  onError(error: any) {
    this.resetCaptcha();
  }

  refresh() {
    this.errorMessage = '';
  }
}
