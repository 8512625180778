<main id="storefront" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Home</li>
    <li class="breadcrumb-item active">My Notifications</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive py-2">
                <table id="table" class="table table-hover table-bordered datatable-dropdown-filter">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Snippet</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="clickable-row" (click)="clickNotification(notification)" *ngFor="let notification of allNotifications">
                      <td><span [hidden]="notification.is_read" style="color: red">[Unread]</span><span [hidden]="!notification.is_read"
                        style="color: green">[Read]</span> {{ notification.title }}</td>
                      <td>{{ notification.snippet }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</main>
<div class="modal fade" id="viewNotification" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ selectedNotification?.title}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="selectedNotification?.content"></div>
        <div class="modal-footer">
            <button type="button" class="btn btn-custom-outline" data-bs-dismiss="modal">Dismiss</button>
        </div>
      </div>
  </div>
</div>
