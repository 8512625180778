import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class StoreService {
	storefrontName;
	storefrontCode;
	department;
	address;
	email;
	phoneNumber;
	stateId;
	countryId;
	selectedCover;
	coverTitle;
	coverText;
	themeId;
	customPageStatus;
	selectedPreview;
	pageContent;
	pageTitle;
	monthlyPrice;
	yearlyPrice;
	selectedProducts;
	logo;
	customCover;
	locationCurrency
	companyMerchant
	acceptRecurring
	clientId
	secretKey
	previewThemeCss

	apiUrl = environment.apiUrl;
  	mainApiUrl = environment.mainApiUrl;

	constructor(private http: HttpClient) { }

	initializeStore(storefrontName, storefrontCode, department, address, email, phoneNumber,
		stateId, countryId, coverTitle, coverText, logo) {
		this.storefrontName = storefrontName;
		this.storefrontCode = storefrontCode;
		this.department = department;
		this.address = address;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.stateId = stateId;
		this.countryId = countryId;
		this.coverTitle = coverTitle;
		this.coverText = coverText;
		this.logo = logo;
	}
	setupThemeAndCover(themeId, coverId, selectedPreview, customCover, previewThemeCss) {
		this.themeId = themeId;
		this.selectedCover = coverId;
		this.selectedPreview = selectedPreview;
		this.customCover = customCover;
		this.previewThemeCss = previewThemeCss
	}

	setupCustomPageAndTitle(pageTitle, pageContent) {
		this.pageTitle = pageTitle;
		this.pageContent = pageContent;
	}

	setupPrice(monthlyPrice, yearlyPrice, selectedProducts) {
		this.monthlyPrice = monthlyPrice;
		this.yearlyPrice = yearlyPrice;
		this.selectedProducts = selectedProducts;
	}

	setupPayment(locationCurrency,companyMerchant,acceptRecurring,clientId,secretKey){
		this.locationCurrency = locationCurrency
		this.companyMerchant = companyMerchant
		this.acceptRecurring = acceptRecurring
		this.clientId = clientId
		this.secretKey = secretKey
	}
	getSelectedPreview() {
		return this.selectedPreview;
	}

	getStorefront(id){
		const httpHeaders = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
		};
		return this.http.get(
			`${this.apiUrl}storefront/get-storefront-by-id/`+id,
			httpHeaders
			);

	}

	checkStorefrontExist(storefrontCode) {
		const httpHeaders = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
		};

		const data = {
			storefront_code: storefrontCode
		};

		return this.http.post(
			`${this.apiUrl}storefront/check-storefront-exists`,
			data,
			httpHeaders
			);
	}
}
