

    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item active">Client Management</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive py-2">
                    <table id="table" class="table table-hover table-bordered datatable-dropdown-filter">
                      <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Access</th>
                            <th>Partner ID</th>
                            <th>Storefront ID</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr class="clickable-row" *ngFor="let client of clients" (click)="show(client)">
                          <td>{{client.user.email}}</td>
                          <td>{{client.first_name}} {{client.last_name}}</td>
                          <td>
                            <span *ngIf="client.user.status_email == 0" class="badge badge-warning">Pending Activation</span>
                            <span *ngIf="client.user.status_account == 1" class="badge badge-success">Active</span>
                            <span *ngIf="client.user.status_account == 0" class="badge badge-danger">Suspended</span>
                          </td>
                          <td>{{client.access_level.name}}</td>
                          <td>{{client.storefront.rescode}}</td>
                          <td>{{client.storefront.storefront_name}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Access</th>
                          <th>Partner ID</th>
                          <th>Storefront ID</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>
