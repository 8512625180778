<main id="manage-order" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Home</li>
    <li class="breadcrumb-item">Order Management</li>
    <li class="breadcrumb-item active">Order Details</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>Oops!</strong> Something went wrong.
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="closeErrorPressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="successCheck">
        <div class="col-12">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong>&nbsp;{{ successMessage }}
            <button type="button" class="close" (click)="closeSuccessPressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">Order Information</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td>Product</td>
                  <td>{{ order?.productName }}</td>
                </tr>
                <tr>
                  <td>Package</td>
                  <td>{{ order?.packageName }}</td>
                </tr>
                <tr>
                  <td>Order Id</td>
                  <td>{{ order?.orderid }}</td>
                </tr>
                <tr>
                  <td>Partner Id</td>
                  <td>{{ order?.rescode }}</td>
                </tr>
                <tr *ngIf="order.storefrontname && order.storefrontname !='' ">
                  <td>Storefront</td>
                  <td>{{ order?.storefrontname }}</td>
                </tr>
                <tr>
                  <td>Mail Instance Id</td>
                  <td>{{ order?.mailInstanceId }}</td>
                </tr>
                <tr>
                  <td>Order Date</td>
                  <td>{{ order?.dtorder | date:'medium' }}</td>
                </tr>
                <tr *ngIf="order.dtcomplete">
                  <td>Complete Date</td>
                  <td>{{ order?.dtcomplete | date:'medium' }}</td>
                </tr>
                <tr *ngIf="order.dtexpiry">
                  <td>Expiry Date</td>
                  <td>{{ order?.dtexpiry | date:'medium' }}</td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td>{{order?.paymentmethodname}}</td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td>{{order?.paymentStatusValue}}</td>
                </tr>
                <tr *ngIf="order.payfailurereason && order.payfailurereason !=''">
                  <td>Failure Reason</td>
                  <td>{{order?.payfailurereason}}</td>
                </tr>
                <tr *ngIf="order.referenceno">
                  <td>Ref. No.</td>
                  <td>{{order?.referenceno}}</td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td>{{order?.statusValue}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-5">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">Product Details</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td>Email Domain</td>
                  <td>{{ order?.domemail }}</td>
                </tr>
                <tr>
                  <td>Account Storage (GB)</td>
                  <td>{{ order?.mailstorage }}</td>
                </tr>
                <tr>
                  <td>Shared Storage (GB)</td>
                  <td>{{ order?.sharedstorage }}</td>
                </tr>
                <tr>
                  <td>Personal Storage (GB)</td>
                  <td>{{ order?.personalstorage }}</td>
                </tr>
                <tr>
                  <td>License Count</td>
                  <td>{{ order?.licenses }}</td>
                </tr>
                <tr>
                  <td>Subscribed Month</td>
                  <td>{{ order?.terms }}</td>
                </tr>
                <tr>
                  <td>Total Price (USD)</td>
                  <td>{{ order?.totalPrice }}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="3">Actions</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td colspan="2">Update Payment Status</td>
                  <td style="width: 30%;">
                    <button class="btn btn-block btn-primary" (click)="openModifyPaymentStatusModal(modifyPaymentStatusModal)"
                     [disabled]="modifyPaymentStatusBtnPressed || !enableModifyPaymentStatus "> Go
                      <span *ngIf="modifyPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="enableModifyAddLicensesOAPaymentStatus">
                  <td colspan="2">Update Add Licenses Payment Status
                    <i class="fa fa-exclamation-circle text-info" placement="right" triggers="click:blur hover focus"
                    tooltip="This action is for updating the payment status of add licenses order request (offline payment) from storefront client">
                    </i>
                  </td>
                  <td >
                    <button class="btn btn-block btn-primary" (click)="openModifyAddLicensesOAPaymentStatusModal(modifyAddLicensesOAPaymentStatusModal)"
                     [disabled]="modifyAddLicensesOAPaymentStatusBtnPressed"> Go
                      <span *ngIf="modifyAddLicensesOAPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Update Status</td>
                  <td>
                    <button class="btn btn-block btn-primary" (click)="openModifyStatusModal(modifyStatusModal)"
                     [disabled]="modifyStatusBtnPressed || !enableModifyStatus "> Go
                      <span *ngIf="modifyStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Update Mail Instance ID</td>
                  <td>
                    <button class="btn btn-block btn-primary" (click)="openModifyMailInstanceModal(modifyMailInstanceModal)"
                     [disabled]="modifyMailInstanceBtnPressed || !enableModifyMailInstance"> Go
                      <span *ngIf="modifyMailInstanceBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Cancel Order</td>
                  <td>
                    <button class="btn btn-block btn-primary" (click)="openCancelOrderModal(cancelOrderModal)"
                    [disabled]="cancelOrderBtnPressed || !enableCancelOrder"> Go
                      <span *ngIf="cancelOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Complete Order</td>
                  <td>
                    <button class="btn btn-block btn-primary" (click)="openCompleteOrderModal(completeOrderModal)"
                    [disabled]="completeOrderBtnPressed || !enableCompleteOrder"> Go
                      <span *ngIf="completeOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="float-right">
            <a routerLink="/orders" class="btn btn-dark btn-lg px-5 mb-4">Back</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->

  <!-- Modal -->
  <ng-template #modifyPaymentStatusModal>
    <div class="modal-header">
      <h4 class="modal-title">Update Payment Status</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyPaymentStatusForm" id="modifyPaymentStatusForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            Reference No
            <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
            tooltip="This feature is for 'Bank Transfer' payment method, updating the reference no. will override any previously saved payment
             method and update the order's payment status to 'Complete'. It will trigger to perform the SOA transactions of the order.">
            </i>
          </div>
          <input formControlName="referenceno" class="form-control my-2" />
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyPaymentStatusForm.invalid || modifyPaymentStatusBtnPressed"
       (click)="modifyPaymentStatusPressed()">
        Update <span *ngIf="modifyPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">Close</button>
    </div>
  </ng-template>

  <ng-template #modifyAddLicensesOAPaymentStatusModal>
    <div class="modal-header">
      <h4 class="modal-title">Update Add Licenses Payment Status</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyAddLicensesOAPaymentStatusForm" id="modifyAddLicensesOAPaymentStatusForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            Add Licenses Order Requests
            <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
            tooltip="Please ensure to select the correct order request which been paid according the payment 
            transaction records provided by the client">
            </i>
          </div>
          <select formControlName="oaid" class="form-control my-2">
            <option value="null" disabled="true">Select an order request to update</option>
            <option *ngFor="let oa of pendingPaymentAddLicensesOAList" [value]="oa.oaid">
              Total price = USD {{oa.totalprices | number}}, for additional license(s) of {{oa.licenses}}
            </option>
          </select>
        </div>
        <div class="group form-group mb-4">
          <div class="mb-1">
            Reference No
            <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
            tooltip="This reference no. input here is for 'Bank Transfer' payment method only, updating the reference no. will update the selected 
            add licenses order request to payment status of 'Complete'. It will trigger to perform the add licenses SOA transactions and then
            save the new licenses amount to existing order.">
            </i>
          </div>
          <input formControlName="referenceno" class="form-control my-2" />
          <small *ngIf="oaReferencenoFormControl.touched">
            <div *ngIf="oaReferencenoFormControl.hasError('required')" class="text-danger">Reference no. is required.</div>
          </small>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyAddLicensesOAPaymentStatusForm.invalid || modifyAddLicensesOAPaymentStatusBtnPressed"
       (click)="modifyAddLicensesOAPaymentStatusPressed()">
        Update <span *ngIf="modifyAddLicensesOAPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">Close</button>
    </div>
  </ng-template>

  <ng-template #modifyStatusModal>
    <div class="modal-header">
      <h4 class="modal-title">Update Order Status</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyStatusForm" id="modifyStatusForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            Status
          </div>
          <select name="statusOptions" formControlName="status" id="statusOptionsId" class="form-control my-2">
            <option [value]="'NEW'">New</option>
            <option [value]="'PENDING'">Pending</option>
          </select>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyStatusForm.invalid || modifyStatusBtnPressed"
       (click)="modifyStatusPressed()">
        Update <span *ngIf="modifyStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">Close</button>
    </div>
  </ng-template>

  <ng-template #modifyMailInstanceModal>
    <div class="modal-header">
      <h4 class="modal-title">Update Mail Instance Id</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyMailInstanceForm" id="modifyMailInstanceForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            Instance Id
          </div>
          <input formControlName="instanceId" id="instanceId" type="text" class="form-control"
          [ngClass]=" {'is-invalid': instanceIdFormControl.invalid && !instanceIdFormControl.pristine }" />
          <p *ngIf="!instanceIdFormControl.pristine && instanceIdFormControl.hasError('required')" class="text-danger">
            Instance Id is required.
          </p>
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyMailInstanceForm.invalid || modifyMailInstanceBtnPressed"
       (click)="modifyMailInstancePressed()">
        Update <span *ngIf="modifyMailInstanceBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">Close</button>
    </div>
  </ng-template>

  <ng-template #cancelOrderModal>
    <div class="modal-header">
      <div class="modal-title">Cancel Order Confirmation</div>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <div class="py-4">Do you confirm to cancel this order?</div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="cancelOrderBtnPressed"
       (click)="cancelOrderConfirmationPressed()">
        Confirm <span *ngIf="cancelOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">Close</button>
    </div>
  </ng-template>

  <ng-template #completeOrderModal>
    <div class="modal-header">
      <div class="modal-title">Complete Order Confirmation</div>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <div class="py-4">Do you confirm to complete this order?</div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="completeOrderBtnPressed"
       (click)="completeOrderConfirmationPressed()">
        Confirm <span *ngIf="completeOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">Close</button>
    </div>
  </ng-template>

</main>
