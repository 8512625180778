import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  apiUrl = environment.apiUrl;
  mainApiUrl = environment.mainApiUrl;

  httpHeaders = {
    headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  constructor(
    private http: HttpClient
  ) { }

  getAllOrders(){
    return this.http.get(this.apiUrl+'order/get-all-orders', this.httpHeaders)
  }

  getOrderById(id){
    return this.http.get(this.apiUrl+'order/get-order-by-order-id?order_id='+id, this.httpHeaders)
  }

  postPaymentStatus(id, sf_id, sf_user_id){
    let data = {
      order_id : id,
      sf_id : sf_id,
      sf_user_id : sf_user_id
    }
    return this.http.post(this.apiUrl+'order/mark-payment-as-done', data, this.httpHeaders)
  }

  postProceedOrder(id, sf_id, sf_user_id){
    let data = {
      order_id : id,
      sf_id : sf_id,
      sf_user_id : sf_user_id
    }
    return this.http.post(this.apiUrl+'order/proceed-order', data, this.httpHeaders)
  }

  postRefundOrder(order_id, item_id, reason, sf_id, sf_user_id) {
    let data = {
      'order_id': order_id,
      'item_id': item_id,
      'refund_reason': reason,
      'sf_id': sf_id,
      'sf_user_id': sf_user_id
    };

    return this.http.post(this.apiUrl+'order/refund', data, this.httpHeaders)
  }


}
